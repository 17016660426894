<script lang="ts" setup>
defineProps({
  service: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['confirm', 'close']);
const modal = useModal();

function close() {
  emit('confirm');
  modal.close();
}
</script>

<template>
  <UModal>
    <UCard :ui="{ base: 'w-full' }">
      <div class="flex flex-col items-start gap-5">
        <div class="flex flex-row justify-between w-full">
          <p class="title capitalize">{{ service }} Disclosure</p>
          <UButton
            :padded="false"
            color="gray"
            variant="link"
            icon="i-ph-x"
            @click="close"
          />
        </div>

        <p class="body dimmed">
          Respell is not responsible for any consequences resulting from the use
          of this feature, including but not limited to account suspension or
          disablement by {{ service }}. Users are solely responsible for
          adhering to Instagram's terms of service and community guidelines.
        </p>
        <p class="body dimmed">
          <strong>Tips to Avoid Account Issues</strong>
        </p>
        <ul class="body dimmed list-disc pl-5">
          <li>
            <strong>Do not send harassing messages:</strong> Respect other users
            and avoid sending any content that could be considered offensive or
            abusive.
          </li>
          <li>
            <strong>Avoid spamming:</strong> Do not use the feature to send
            excessive messages or engage in any behavior that could be perceived
            as spam.
          </li>
          <li>
            <strong
              >Refrain from manual account use during feature operation:</strong
            >
            To prevent conflicting actions, do not use your
            {{ service }} account manually while the Respell feature is running.
          </li>
          <li>
            <strong>Comply with {{ service }}'s policies:</strong> Familiarize
            yourself with and adhere to {{ service }}'s terms of service and
            community guidelines at all times.
          </li>
          <li>
            <strong>Use authentic content:</strong> Avoid using the feature to
            post copyrighted material or content that violates intellectual
            property rights.
          </li>
        </ul>
        <p class="body dimmed">
          By proceeding to use the Respell {{ service }} feature, you confirm
          that you have read, understood, and agree to this disclosure.
        </p>

        <div class="flex flex-row gap-2 justify-between w-full">
          <UButton
            label="Ok"
            variant="solid"
            color="white"
            size="xl"
            block
            class="shrink"
            @click="close"
          />
        </div>
      </div>
    </UCard>
  </UModal>
</template>
